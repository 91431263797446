<!--
// nuxt-ui/components/Shared/stats/GridFeaturedStatsHome.vue
-->
<script setup lang="ts">
import type { SeasonStatsPartner } from "~/src/season-cluster/domain/models/01.Season";
import type { RankingsData, TurnMvpPlayer } from "~/src/player/domain/Player";
import type { BannerCard } from "~/src/content/domain/models/banner";
import CardStatsFeaturedPlayerGeneral from "~/nuxt-ui/components/pages/region-stats/CardStatsFeaturedPlayerGeneral.vue";
import CardStatsTurnMvp from "~/nuxt-ui/components/pages/region-stats/CardStatsTurnMvp.vue";
import CardStoreAd from "~/nuxt-ui/components/Shared/CardStoreAd.vue";
import { usePlayers } from "~/nuxt-ui/Shared/composables/players";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { useSeasons } from "~/nuxt-ui/Shared/composables/season/seasons";
import bannerUiService from "~/src/content/infrastructure/ui-services/bannerUiService";
//import playerUiService from "~/src/player/infrastrructure/ui-services/playerUiService";

const { getLastTurnMvp } = usePlayers();
const {
  useFetchParamsSeasonStatsFirstPlayer,
  useFetchParamsSeasonStatsFirstGoalkeeperKWC,
  useFetchParamsSeasonStatsFirstCards,
  useFetchParamsSeasonStatsFirstGoalkeeper,
} = useSeasons();
const { regionParam, isAmericas, isSpain, isWorldCup } = useRegions();
const { isKings, isQueens, webContext, webContextGenders } = useWebContext();
const { currentSeason, region } = useCompetitionStore();
const { getCardBanner } = bannerUiService();
const localePath = useLocalePath();
// const { getCurrentSeasonRandomPlayers } = playerUiService();

const mvpPartner: SeasonStatsPartner | undefined = currentSeason?.metaInformation?.stats_partner
  ? currentSeason?.metaInformation?.stats_partner
  : undefined;

const turnMvp = ref<TurnMvpPlayer | undefined>();
const topMvps = ref<RankingsData | undefined>();
//const topScorer = ref<RankingsData>();
//const topAssistant = ref<RankingsData>();
const bestGoalkeeper = ref<RankingsData>();
//const topCards = ref<GroupedRanking>();

const storeBanner = ref<BannerCard | undefined>();

// const randomTurnMvp = ref<Player>();
// const randomTopScorer = ref<Player>();
// const randomTopAssistant = ref<Player>();
// const randomBestGoalkeeper = ref<Player>();
// const randomTopCards = ref<Player>();

turnMvp.value = currentSeason && currentSeason?.id !== 13 ? await getLastTurnMvp(currentSeason) : undefined;

const fetchOptsMvps =
  currentSeason?.id === 13 ? useFetchParamsSeasonStatsFirstPlayer(currentSeason.id, "MVP") : undefined;
if (fetchOptsMvps) {
  const { path: pathMvps, opts: optsMvps } = fetchOptsMvps;
  const { data } = await useFetch(pathMvps, optsMvps);
  topMvps.value = data.value;
}

const { path: pathAss, opts: optsAss } = useFetchParamsSeasonStatsFirstPlayer(currentSeason?.id, "ASS-V");
const { data: topAssistant } = await useFetch(pathAss, optsAss);

const { path: pathScorer, opts: optsScorer } = useFetchParamsSeasonStatsFirstPlayer(currentSeason?.id, "GOL");
const { data: topScorer } = await useFetch(pathScorer, optsScorer);

/* Best GoalKeeper */
const fetchOptsGoalkeepersKWC =
  currentSeason?.id && isWorldCup ? useFetchParamsSeasonStatsFirstGoalkeeperKWC(currentSeason.id) : undefined;
const fetchOptsGoalkeeperSpain =
  currentSeason?.id && isSpain ? useFetchParamsSeasonStatsFirstGoalkeeper(currentSeason.id) : undefined;
const fetchOptsGoalkeeper = currentSeason?.id
  ? useFetchParamsSeasonStatsFirstPlayer(currentSeason.id, "PRT")
  : undefined;
if (fetchOptsGoalkeepersKWC) {
  const { path: pathGkKwc, opts: optsGkKwc } = fetchOptsGoalkeepersKWC;
  const { data } = await useFetch(pathGkKwc, optsGkKwc);
  bestGoalkeeper.value = data.value;
} else if (fetchOptsGoalkeeperSpain) {
  const { path: pathGkSp, opts: optsGkSp } = fetchOptsGoalkeeperSpain;
  const { data } = await useFetch(pathGkSp, optsGkSp);
  bestGoalkeeper.value = data.value;
} else {
  const { path: pathGk, opts: optsGk } = fetchOptsGoalkeeper;
  const { data } = await useFetch(pathGk, optsGk);
  bestGoalkeeper.value = data.value;
}
/* END Best GoalKeeper */

const { path: pathCards, opts: optsCards } = useFetchParamsSeasonStatsFirstCards(currentSeason?.id);
const { data: topCards } = await useFetch(pathCards, optsCards);

onMounted(async () => {
  await nextTick();
  // if (currentSeason?.id === 13) {
  //   const [playerOne] = await getCurrentSeasonRandomPlayers(2);
  //   const [randomGk] = await getCurrentSeasonRandomPlayers(1, "goalkeeper");
  //
  //   randomTurnMvp.value = playerOne;
  //   randomTopScorer.value = playerTwo;
  //   randomTopAssistant.value = playerThree;
  //   randomBestGoalkeeper.value = randomGk;
  //   randomTopCards.value = playerFour;
  // }
  storeBanner.value = await getCardBanner("home");
});

// const playerHasAlltheParameters = (player: Player | undefined): boolean => {
//   if (!player) return false;
//   return !!(player.shortName && player.teams && player.teams.length && player.image?.url);
// };
</script>

<template>
  <div class="md:bg-gray-900 mt-8 md:p-4 rounded-lg lg:mt-0">
    <div class="flex text-gray-400 uppercase text-sm mb-2 justify-between items-end">
      <h2>{{ $t("general.stats.title") }}</h2>

      <nuxt-link
        :to="
          localePath({
            name: `region-Stats`,
            params: { region: regionParam },
          })
        "
        class="normal-case text-primary text-xs"
      >
        {{ $t("cards.stats.seeMore.link") }}
      </nuxt-link>
    </div>

    <hr />

    <div class="main-container-cards">
      <card-stats-turn-mvp
        v-if="topMvps && topMvps.player && isWorldCup"
        :featured-player-image="topMvps.player.image.url"
        :featured-player-name="topMvps.player.shortName"
        :featured-player-team-image="topMvps.team.logo.url"
        :featured-player-stat="topMvps.total"
        :turn-number="topMvps.total"
        :has-partner="!!mvpPartner"
        :partner-logo="mvpPartner?.logo"
        :partner-name="mvpPartner?.name"
        :table-stat-title="$t('general.stats.mvps')"
        page-stats-link="Mvp"
        hide-turn
      ></card-stats-turn-mvp>
      <card-stats-turn-mvp
        v-if="turnMvp && turnMvp.player && !isWorldCup"
        :featured-player-image="turnMvp.playerSeasonInfo?.playerImage?.url"
        :featured-player-name="turnMvp.player.shortName"
        :featured-player-team-image="turnMvp.playerSeasonInfo?.team?.logo?.url"
        :featured-player-stat="1"
        :turn-number="turnMvp.turnName"
        :has-partner="!!mvpPartner"
        :partner-logo="mvpPartner?.logo"
        :partner-name="mvpPartner?.name"
        :table-stat-title="$t('general.stats.mvps')"
        page-stats-link="TurnMvp"
        hide-stat
      ></card-stats-turn-mvp>

      <!--      <card-stats-featured-player-general-->
      <!--        v-if="isWorldCup && playerHasAlltheParameters(randomTopScorer)"-->
      <!--        :featured-player-image="randomTopScorer?.image.url"-->
      <!--        :featured-player-name="randomTopScorer?.shortName"-->
      <!--        :featured-player-team-image="randomTopScorer?.teams[0].logo?.url"-->
      <!--        :featured-player-stat="0"-->
      <!--        :pre-title-stat="$t('cards.stats.pretitle.top')"-->
      <!--        :title-stat="$t('cards.stats.title.scorer')"-->
      <!--        :table-stat-title="$t('general.stats.goals')"-->
      <!--        page-stats-link="GoalsGrouped"-->
      <!--      ></card-stats-featured-player-general>-->
      <card-stats-featured-player-general
        v-if="topScorer"
        :featured-player-image="topScorer?.player?.image.url"
        :featured-player-name="topScorer?.player?.shortName"
        :featured-player-team-image="topScorer?.team?.logo.url"
        :featured-player-stat="topScorer.total"
        :pre-title-stat="$t('cards.stats.pretitle.top')"
        :title-stat="$t('cards.stats.title.scorer')"
        :table-stat-title="$t('general.stats.goals')"
        page-stats-link="GoalsGrouped"
      ></card-stats-featured-player-general>

      <!--      <card-stats-featured-player-general-->
      <!--        v-if="isWorldCup && playerHasAlltheParameters(randomTopAssistant)"-->
      <!--        :featured-player-image="randomTopAssistant?.image.url"-->
      <!--        :featured-player-name="randomTopAssistant?.shortName"-->
      <!--        :featured-player-team-image="randomTopAssistant?.teams[0]?.logo?.url"-->
      <!--        :featured-player-stat="0"-->
      <!--        :pre-title-stat="$t('cards.stats.pretitle.top')"-->
      <!--        :title-stat="$t('cards.stats.title.assistant')"-->
      <!--        :table-stat-title="$t('general.stats.assists')"-->
      <!--        page-stats-link="Offensives"-->
      <!--      ></card-stats-featured-player-general>-->
      <card-stats-featured-player-general
        v-if="topAssistant"
        :featured-player-image="topAssistant?.player?.image.url"
        :featured-player-name="topAssistant?.player?.shortName"
        :featured-player-team-image="topAssistant?.team?.logo.url"
        :featured-player-stat="topAssistant?.total"
        :pre-title-stat="$t('cards.stats.pretitle.top')"
        :title-stat="$t('cards.stats.title.assistant')"
        :table-stat-title="$t('general.stats.assists')"
        page-stats-link="Passing"
      ></card-stats-featured-player-general>

      <!--      <card-stats-featured-player-general-->
      <!--        v-if="isWorldCup && playerHasAlltheParameters(randomBestGoalkeeper)"-->
      <!--        :featured-player-image="randomBestGoalkeeper?.image.url"-->
      <!--        :featured-player-name="randomBestGoalkeeper?.shortName"-->
      <!--        :featured-player-team-image="randomBestGoalkeeper?.teams[0]?.logo?.url"-->
      <!--        :featured-player-stat="0"-->
      <!--        :pre-title-stat="$t('cards.stats.pretitle.best')"-->
      <!--        :title-stat="$t('cards.stats.title.goalkeeper')"-->
      <!--        :table-stat-title="$t('Par.')"-->
      <!--        page-stats-link="Goalkeeper"-->
      <!--      ></card-stats-featured-player-general>-->
      <card-stats-featured-player-general
        v-if="bestGoalkeeper"
        :featured-player-image="bestGoalkeeper?.player?.image.url"
        :featured-player-name="bestGoalkeeper?.player?.shortName"
        :featured-player-team-image="bestGoalkeeper?.team?.logo?.url"
        :featured-player-stat="isWorldCup || isSpain ? bestGoalkeeper?.totalAVG : bestGoalkeeper?.total"
        :pre-title-stat="$t('cards.stats.pretitle.best')"
        :title-stat="$t('cards.stats.title.goalkeeper')"
        :table-stat-title="isWorldCup || isSpain ? 'Ratio' : $t('Par.')"
        page-stats-link="Goalkeeper"
        :disclaimer="isWorldCup ? $t('cards.stats.disclaimer.bestGkKWC') : undefined"
      ></card-stats-featured-player-general>

      <!--      <card-stats-featured-player-general-->
      <!--        v-if="isWorldCup && playerHasAlltheParameters(randomTopCards)"-->
      <!--        is-cards-stats-->
      <!--        :featured-player-image="randomTopCards?.image.url"-->
      <!--        :featured-player-name="randomTopCards?.shortName"-->
      <!--        :featured-player-team-image="randomTopCards?.teams[0]?.logo?.url"-->
      <!--        :featured-player-stat="0"-->
      <!--        :featured-player-second-stat="0"-->
      <!--        :title-stat="$t('cards.stats.title.cards')"-->
      <!--        page-stats-link="Cards"-->
      <!--      ></card-stats-featured-player-general>-->
      <card-stats-featured-player-general
        v-if="topCards && topCards?.rankingData['CRT-G'].total"
        :featured-player-image="topCards.player.image.url"
        :featured-player-name="topCards.player.shortName"
        :featured-player-team-image="topCards.team.logo.url"
        :featured-player-stat="topCards.rankingData['CRT-G'].total"
        :featured-player-second-stat="topCards.rankingData['CRT-R'].total"
        :title-stat="$t('cards.stats.title.cards')"
        page-stats-link="Cards"
        is-cards-stats
      ></card-stats-featured-player-general>

      <card-store-ad
        v-if="
          storeBanner &&
          storeBanner.image &&
          storeBanner.webContext.includes(isWorldCup ? 'world-cup' : webContext)
        "
        light
        :product-image="storeBanner.image"
        :product-title="storeBanner.description"
        :product-link="storeBanner.link"
        :product-link-target="storeBanner.linkTarget"
        title-card="Tienda oficial"
      ></card-store-ad>
    </div>
  </div>
</template>

<style scoped>
.main-container-cards {
  @apply grid grid-cols-1 justify-items-center sm:grid-cols-2 md:grid-cols-3 md:grid-rows-[repeat(2,minmax(0,568px))] gap-x-4 gap-y-[35px] mt-4;
}
</style>
